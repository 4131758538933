import axios from 'axios';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { cn } from '../../utils/cn';

const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogOut = async () => {
    const BACKEND_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('JWT');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axios.get(`${BACKEND_URL}/logout`, {}, config);
    localStorage.removeItem('JWT');
    navigate('/login');
  };

  const navItems = [
    {
      title: 'Getting Started',
      path: '/dashboard/app',
    },
    {
      title: 'Models',
      path: '/dashboard/model',
    },
  ];

  return (
    <nav className="bg-white border-gray-200 shadow py-1">
      <div className="max-w-screen-xl flex w-full justify-between items-center mx-auto p-2.5">
        <div className="flex whitespace-nowrap">
          <a href="/" className="flex items-center text-2xl font-bold">
            BCI OS
          </a>
          <div className={'w-full ml-6'} id="navbar-dropdown">
            <ul className="flex font-medium gap-4 flex-row grow">
              {navItems.map((item, index) => {
                const isCurrent = location.pathname.startsWith(item.path);
                return (
                  <li key={index}>
                    <button
                      type="button"
                      onClick={() => {
                        navigate(item.path);
                      }}
                      className={cn(
                        'block px-2 py-1.5 text-gray-500 text-sm rounded-md',
                        isCurrent && 'bg-gray-100 text-gray-700'
                      )}
                    >
                      {item.title}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <button
          type="button"
          onClick={() => {
            handleLogOut();
          }}
          className="p-2 text-sm rounded-lg text-red-500"
        >
          Sign Out
        </button>
      </div>
    </nav>
  );
};

export default function DashboardLayout() {
  return (
    <div>
      <Nav />
      <div className="flex justify-center mt-8 pb-10 mb-10">
        <Outlet />
      </div>
    </div>
  );
}
