import React, { useState } from 'react'
import PropTypes from 'prop-types';

export function useForm(initialFormValues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = e => {
    const { name, value, files } = e.target;
 
    setValues({
      ...values,
      [name]: files || value
    });
  
    if (validateOnChange) {
      validate({ [name]: value });
    }
  }

  const resetForm = () => {
    setValues(initialFormValues);
    setErrors({});
  }

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  }
}

export function Form(props) {
  const { children, ...other } = props;

  return (
    <form autoComplete="off" {...other}>
      {children}
    </form>
  )
}

Form.propTypes = {
  children: PropTypes.node,
};
