import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { cn } from '../../utils/cn'

export default function File(props) {
  const { id, name, label, value, helperText, error = null, onChange } = props;
  const [_, setFileName] = useState('');

  useEffect(() => {
    if (value) {
      setFileName(value[0]?.name);
    } else {
      setFileName('');
    }
  }, [value]);

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="block mb-2 text-sm font-bold text-gray-900">
        {label}
        <input
          className={cn(
            'block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none',
            error && 'border-red-500'
          )}
          id={id}
          name={name}
          onChange={onChange}
          type="file"
        />
      </label>
      {helperText && <p className="mt-1 text-sm text-gray-500">{helperText}</p>}
      {error && <div className="text-xs text-red-500">{error}</div>}
    </div>

    // <div style={{
    //     width: `${fullWidth ? "100%" : "auto"}`,
    // }}>
    //   <div style={{
    //     width: `${fullWidth ? "100%" : "auto"}`,
    //     border: `1px solid ${error ? 'red' : 'rgba(145, 158, 171, 0.32)'}`,
    //     padding: "10px",
    //     borderRadius: "6px"
    //   }}>
    //     <label htmlFor={id}>
    //       <TextField
    //         type="file"
    //         id={id}
    //         name={name}
    //         onChange={onChange}
    //         style={{ display: "none" }}
    //       />

    //       {label && <span style={{ color: error ? 'red' : 'inherit' }}>{label}:</span>}
    //       {<span style={{ color: error ? 'red' : 'inherit', marginLeft: "4px", marginRight: "4px" }}>{fileName || "No file selected"}</span>}

    //       <Button variant="outlined" component="span">
    //         Select file
    //       </Button>
    //     </label>
    //   </div>

    //   {error && <div style={{ marginLeft: "14px", color: "red", fontSize: "0.75rem" }}>{error}</div>}
    // </div>
  );
}

File.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
};
