import PropTypes from 'prop-types';
import { cn } from '../utils/cn';

export function Container({ fluid = false, headerOffset = false, className, style, children }) {
  return (
    <div
      className={cn(
        'container md:px-4 mx-8',
        fluid && 'max-w-full p-0 m-0',
        !fluid && 'max-w-7xl',
        headerOffset && 'pt-16',
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
}

Container.propTypes = {
  fluid: PropTypes.bool,
  headerOffset: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.string,
  children: PropTypes.node.isRequired,
};
