import { Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { fDate } from '../../../utils/formatTime';

AppModelsUpdate.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppModelsUpdate({ title, loading, list, ...other }) {
  const navigate = useNavigate();

  const handleOpenModels = () => {
    navigate(`/dashboard/model/`);
  };

  const handleOpenModel = (id) => {
    navigate(`/dashboard/model/${id}`);
  };

  return (
    <div {...other} className="pt-8">
      <div className="flex w-full justify-between pb-6">
        <h2 className="font-bold text-2xl md:text-4xl">{title}</h2>
        <button
          type="button"
          onClick={handleOpenModels}
          className="inline-flex items-center px-3 py-2 font-medium text-center text-gray-900 bg-white rounded-lg border border-black text-base"
        >
          View all
        </button>
      </div>

      <div className="flex flex-col gap-6">
        {loading
          ? Array.from(new Array(5)).map((_, index) => <SkeletonItem key={index} />)
          : list.map((model) => <ModelItem key={model.id} model={model} onClick={() => handleOpenModel(model.id)} />)}
      </div>
    </div>
  );
}

ModelItem.propTypes = {
  model: PropTypes.object,
  onClick: PropTypes.func,
};

function ModelItem({ model, onClick }) {
  const {
    description,
    text,
    created_at: createdAt,
    job_id,
    completed_training_rounds: completedTrainingRounds,
    accuracy,
    tags,
  } = model;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="p-6 bg-white border cursor-pointer border-gray-200 rounded-lg shadow"
      onMouseDown={() => {
        onClick();
      }}
      onKeyDown={() => {
        onClick();
      }}
    >
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{description}</h5>
      {text && <p className="mb-3 font-normal text-base text-gray-500">{text}</p>}
      <div className="flex flex-col gap-4 mt-6 text-gray-500 text-xs md:items-center md:flex-row">
        {tags && tags.length > 0 && (
          <div className="flex gap-1">
            {tags.map((tag) => (
              <>
                {tag && (
                  <div className="bg-gray-100 rounded p-1 flex" key={tag}>
                    {tag}
                  </div>
                )}
              </>
            ))}
          </div>
        )}
        {tags && tags.length > 0 && (
          <div className='hidden md:flex'>
            <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
            </svg>
          </div>
        )}
        <div>{job_id ? `Has Budget` : `No Budget`}</div>
        <div className='hidden md:flex'>
          <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
          </svg>
        </div>
        <div>
          {!completedTrainingRounds ? '0' : `${completedTrainingRounds}`} Complete Training Round
          {completedTrainingRounds === 1 ? '' : 's'}
        </div>

        {accuracy && (
          <div className='hidden md:flex'>
            <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
            </svg>
          </div>
        )}
        {accuracy && <div>{accuracy} Accuracy</div>}
        <div className='hidden md:flex'>
          <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
          </svg>
        </div>
        <div>Created {fDate(createdAt)}</div>
      </div>
    </div>
  );
}

ModelItem.propTypes = {
  model: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

function SkeletonItem() {
  return (
    <div className="p-6 bg-white border border-gray-200 rounded-lg shadow">
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
        <Skeleton variant="text" />
      </h5>
      <p className="mb-3 font-normal text-base text-gray-500">
        <Skeleton variant="text" />
      </p>
      <div className="flex gap-4 text-gray-500 text-xs">
        <Skeleton variant="text" />
      </div>
    </div>
  );
}
