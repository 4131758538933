import { Alert, Snackbar } from '@mui/material';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount, useBalance } from 'wagmi';

import { Container } from '../components/Container';
import Controls from '../components/form/Controls';
import { Form, useForm } from '../components/form/useForm';
import { Hero } from '../components/Hero';
import { Back } from '../components/Back';
import Budget, { validateBudget } from '../components/web3/Budget';
import { createModel } from '../service';

const initialFormValues = {
  description: '',
  text: '',
  // rounds: '',
  // trainersPerRound: '',
  noiseMultiplier: null,
  maxGradNorm: null,
  modelFile: null,
  datasetFile: null,
  featuresArrayName: '',
  labelsArrayName: '',
  payout: '',
  budget: '',
  tags: '',
};

export default function ModelSubmitPage() {
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const [createModelIsLoading, setCreateModelIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [startCreateJob, setStartCreateJob] = useState(false);

  const { address } = useAccount();
  const { data: balance } = useBalance({ address });

  const validate = (fieldValues = values) => {
    const temp = { ...errors };

    if ('description' in fieldValues) {
      temp.description = fieldValues.description ? '' : 'This field is required.';
    }

    // if ('rounds' in fieldValues) {
    //   temp.rounds = fieldValues.rounds > 0 ? "" : "This field is required.";
    // }

    // if ('trainersPerRound' in fieldValues) {
    //   temp.trainersPerRound = fieldValues.trainersPerRound > 0 ? "" : "This field is required.";
    // }

    if ('modelFile' in fieldValues) {
      temp.modelFile = fieldValues.modelFile?.length ? '' : 'This field is required.';
    }

    if ('datasetFile' in fieldValues) {
      temp.featuresArrayName =
        fieldValues.datasetFile?.length && !values.featuresArrayName
          ? 'This field is required when dataset file is selected.'
          : '';
      temp.labelsArrayName =
        fieldValues.datasetFile?.length && !values.labelsArrayName
          ? 'This field is required when dataset file is selected.'
          : '';
    }

    if ('featuresArrayName' in fieldValues) {
      temp.featuresArrayName =
        values.datasetFile?.length && !fieldValues.featuresArrayName
          ? 'This field is required when dataset file is selected.'
          : '';
    }

    if ('labelsArrayName' in fieldValues) {
      temp.labelsArrayName =
        values.datasetFile?.length && !fieldValues.labelsArrayName
          ? 'This field is required when dataset file is selected.'
          : '';
    }

    const budgetValidation = validateBudget(fieldValues, values, balance);

    setErrors({ ...temp, ...budgetValidation });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }

    return false;
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFormValues, true, validate);

  const handleCreateJob = async (jobId) => {
    setStartCreateJob(false);
    const model = await createModel(jobId, values);

    if (model.success) {
      navigate(`/dashboard/model/${model.data.id}`);
    } else {
      setAlertMessage(model.message);
      setOpenAlert(true);
      setCreateModelIsLoading(false);
    }
  };

  const handleCreateJobError = async (error) => {
    setStartCreateJob(false);
    setCreateModelIsLoading(false);
    setAlertMessage(error.message.split('.')[0]);
    setOpenAlert(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setCreateModelIsLoading(true);

      if (values.payout && values.budget) {
        setStartCreateJob(true);
      } else {
        handleCreateJob();
      }
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <Container>
      <Hero
        leftItem={
          <Back
            onClick={() => {
              navigate(-1);
            }}
          />
        }
        copy={'Submit Model'}
      />
      <Snackbar open={openAlert} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error" onClose={handleCloseAlert} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>

      <Form onSubmit={handleSubmit}>
        <div className="mt-6 p-6 bg-white border border-gray-200 rounded-lg w-full shadow">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Model information</h5>

          <div>
            <Controls.Input
              name="description"
              label="Title"
              value={values.description}
              onChange={handleInputChange}
              error={errors.description}
              placeholder="This is the name users will see while browsing models to train"
            />
          </div>

          <div>
            <Controls.Input
              name="text"
              type="textarea"
              label="Description (optional)"
              value={values.text}
              placeholder='Provide a brief description about your model and share steps for training it successfully.'
              onChange={handleInputChange}
              error={errors.text}
            />
          </div>

          <div>
            <Controls.Input
              name="tags"
              label="Tags"
              value={values.tags}
              onChange={handleInputChange}
              error={errors.tags}
              placeholder='e.g. ECG'
            />
          </div>

          <div className="flex gap-4">
            <Controls.Input
              type="number"
              name="noiseMultiplier"
              label="Noise multiplier"
              value={values.noiseMultiplier}
              onChange={handleInputChange}
              error={errors.noiseMultiplier}
              placeholder="e.g. 1-10000"
            />

            <Controls.Input
              type="number"
              name="maxGradNorm"
              label="Max gradient norm"
              value={values.maxGradNorm}
              onChange={handleInputChange}
              error={errors.maxGradNorm}
              placeholder="e.g. 1-20"
            />
          </div>

          <div>
            <Controls.File
              id="modelFile"
              name="modelFile"
              label="Model file"
              value={values.modelFile}
              onChange={handleInputChange}
              error={errors.modelFile}
              helperText="Max size of 1780tb"
            />
          </div>
        </div>

        <div className="mt-6 p-6 bg-white border border-gray-200 rounded-lg w-full shadow">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Dataset (Optional)</h5>
          <p className="mb-3 font-normal text-base text-gray-500">Testing dataset for computing accuracy</p>
          <div>
            <Controls.File
              id="datasetFile"
              name="datasetFile"
              label="Dataset file"
              value={values.datasetFile}
              onChange={handleInputChange}
              error={errors.datasetFile}
              helperText="Max size of 1780tb"
            />
          </div>

          <div className="flex gap-4 mt-4">
            <Controls.Input
              name="featuresArrayName"
              label="Features array name"
              value={values.featuresArrayName}
              onChange={handleInputChange}
              error={errors.featuresArrayName}
            />

            <Controls.Input
              name="labelsArrayName"
              label="Labels array name"
              value={values.labelsArrayName}
              onChange={handleInputChange}
              error={errors.labelsArrayName}
            />
          </div>
        </div>

        <div className="mt-6 p-6 bg-white border border-gray-200 rounded-lg w-full shadow">
          <div className="flex justify-between pb-8">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Payment (Optional)</h5>

            <ConnectButton />
          </div>

          <Budget
            startCreateJob={startCreateJob}
            values={values}
            errors={errors}
            handleInputChange={handleInputChange}
            handleCreateJobSuccess={handleCreateJob}
            handleCreateJobError={handleCreateJobError}
          />
        </div>
        <div className="mt-6 flex gap-4">
          <button
            type="submit"
            disabled={startCreateJob || createModelIsLoading}
            className="inline-flex items-center px-3 py-2 font-medium text-center text-white bg-gray-900 rounded-lg hover:bg-gray-800 text-base"
          >
            {!startCreateJob && !createModelIsLoading && 'Submit Model'}
            {startCreateJob && 'Confirm the transaction on your wallet...'}
            {!startCreateJob && createModelIsLoading && 'Creating model...'}
          </button>
          <button
            type="button"
            onClick={() => {
              navigate(-1);
            }}
            disabled={startCreateJob || createModelIsLoading}
            className="inline-flex items-center px-3 py-2 font-medium text-center text-gray-900 bg-white rounded-lg border border-black text-base"
          >
            Cancel
          </button>
        </div>
      </Form>
    </Container>
  );
}
