import axios from 'axios';

const modelsUrl = `${process.env.REACT_APP_API_URL}/models`;

const getModels = async () => {
  try {
    const response = await axios.get(modelsUrl);
    return response.data.models;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const countModels = async () => {
  try {
    const response = await axios.get(modelsUrl);
    return response.data.models.length;
  } catch (err) {
    console.error(err);
    return 0;
  }
};

export const getModelById = async (id) => {
  try {
    const modelUrl = `${modelsUrl}/${id}`;
    const response = await axios.get(modelUrl);
    const model = response.data;
    return model;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getLastModels = async (limit = 5) => {
  try {
    const response = await axios.get(modelsUrl);
    const { models } = response.data;
    return models.slice(0, limit);
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const createModel = async (jobId, data) => {
  try {
    const tags = data?.tags.split(',').map((t) => t.trim()).join(',')
    const modeldata = {
      job_id: jobId?.toString(),
      description: data.description,
      tags,
      rounds: 10000, // TODO: Hardcoded for now
      // rounds: data.rounds,
      // trainers_per_round: data.trainersPerRound,
      noise_multiplier: data.noiseMultiplier,
      max_grad_norm: data.maxGradNorm,
      text: data.text,
    };

    const response1 = await axios.post(`${modelsUrl}`, modeldata);

    if (response1.status !== 200) {
      return {
        success: false,
        message: response1.message,
      };
    }

    const model = response1.data;

    if (data.datasetFile && data.datasetFile.length > 0) {
      const uploadValidationData = new FormData();
      uploadValidationData.append('dataset', data.datasetFile[0]);
      uploadValidationData.append(
        'metadata',
        JSON.stringify({ features_array_name: data.featuresArrayName, labels_array_name: data.labelsArrayName })
      );

      const response2 = await axios.put(`${modelsUrl}/${model.id}/tests`, uploadValidationData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response2.status !== 200) {
        return {
          success: false,
          message: response2.message,
        };
      }
    }

    const uploadModelData = new FormData();
    uploadModelData.append('model', data.modelFile[0]);

    const response3 = await axios.put(`${modelsUrl}/${model.id}/upload`, uploadModelData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response3.status !== 200) {
      return {
        success: false,
        message: response3.message,
      };
    }

    return {
      success: true,
      data: model,
    };
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: err.message,
    };
  }
};

export const forkModel = async (id, jobId, data) => {
  try {
    const tags = data?.tags.split(',').map((t) => t.trim()).join(',')
    const modeldata = {
      job_id: jobId?.toString(),
      description: data.description,
      tags,
      text: data.text,
    };

    const response = await axios.post(`${modelsUrl}/${id}/forks`, modeldata);

    if (response.status !== 200) {
      return {
        success: false,
        message: response.message,
      };
    }

    const model = response.data;

    return {
      success: true,
      data: model,
    };
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: err.message,
    };
  }
};

export const updateModel = async (id, jobId, data) => {
  try {
    const tags = data?.tags.split(',').map((t) => t.trim()).join(',')
    const modeldata = {
      job_id: jobId?.toString(),
      description: data?.description,
      tags,
      text: data?.text,
      // rounds: data?.rounds,
      // trainers_per_round: data?.trainersPerRound,
    };

    const response = await axios.put(`${modelsUrl}/${id}`, modeldata);

    if (response.status !== 200) {
      return {
        success: false,
        message: response.message,
      };
    }

    const model = response.data;

    return {
      success: true,
      data: model,
    };
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: err.message,
    };
  }
};

export const deleteModel = async (id) => {
  try {
    const response = await axios.delete(`${modelsUrl}/${id}`);

    if (response.status !== 200) {
      return {
        success: false,
        message: response.message,
      };
    }

    return {
      success: true,
    };
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: err.message,
    };
  }
};

export default getModels;
