import { useNavigate } from 'react-router-dom';

export default function Page404() {
  const navigate = useNavigate();
  return (
    <div className="flex w-full justify-center h-full items-center mt-16">
      <div className="p-6 bg-white border cursor-pointer border-gray-200 rounded-lg shadow">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Sorry, page not found!</h5>
        <p className="mb-3 font-normal text-base text-gray-500">
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
          spelling.
        </p>

        <div className="mt-6 flex gap-4">
          <button
            type="button"
            onClick={() => {
              navigate('/');
            }}
            className="inline-flex items-center px-3 py-2 font-medium text-center text-gray-900 bg-white rounded-lg border border-black text-base"
          >
            Go to Home
          </button>
        </div>
      </div>
    </div>
  );
}
