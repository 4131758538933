import { ethers } from 'ethers';

// ----------------------------------------------------------------------

export function getEventValue(abi, receipt, event, arg) {
  const iface = new ethers.Interface(abi);

  const decodedEvents = receipt.logs
    .map(log => iface.parseLog(log))
    .filter(log => log && log.name === event);

  if (decodedEvents.length === 0) {
    return undefined;
  }

  return decodedEvents[0]?.args[arg]?.toString();
}
