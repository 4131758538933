import PropTypes from 'prop-types';

export function Card({ title, copy, buttonLabel, buttonAction }) {
  return (
    <div className="p-6 bg-white border border-gray-200 rounded-lg w-full shadow">
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{title}</h5>
      <p className="mb-3 font-normal text-base text-gray-500">{copy}</p>
      <button
        type="button"
        onClick={() => {
          buttonAction();
        }}
        className="inline-flex items-center px-5 py-3 font-medium text-center text-white bg-gray-900 rounded-lg hover:bg-gray-800 text-sm"
      >
        {buttonLabel}
      </button>
    </div>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonAction: PropTypes.func.isRequired,
};
