import PropTypes from 'prop-types';

export function Hero({ copy, leftItem, rightItem }) {
  return (
    <div className="flex w-full justify-between items-center pb-6">
      {leftItem || null}
      <h1 className=" text-2xl md:text-4xl font-bold text-left grow">{copy}</h1>
      {rightItem || null}
    </div>
  );
}

Hero.propTypes = {
  copy: PropTypes.string.isRequired,
  leftItem: PropTypes.node,
  rightItem: PropTypes.node,
};
