import { createContext, useContext, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import useLocalStorage from './useStorage';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [jwt, setJWT] = useLocalStorage('JWT', null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setJWT(data);
  };

  const configureAuthorizationHeader = () => {
    axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          localStorage.removeItem('JWT');
          navigate('/login');
        }
        return error;
      }
    );
  };

  // call this function to sign out logged in user
  const logout = () => {
    localStorage.removeItem('JWT');
    navigate('/login', { replace: true });
  };

  const value = useMemo(
    () => ({
      jwt,
      login,
      logout,
      configureAuthorizationHeader,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jwt]
  );

  useEffect(() => {
    if (!jwt) return;

    
    if (pathname === "/login") {
      const returnUrl = localStorage.getItem('returnUrl');
      localStorage.removeItem('returnUrl');

      navigate(returnUrl || '/dashboard/app', { replace: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
