import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

ModelListToolbar.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  modelTypes: PropTypes.array,
  onFilterTag: PropTypes.func,
};

export default function ModelListToolbar({ filterName, onFilterName, modelTypes, onFilterTag }) {
  const [showFilters, setShowFilters] = useState(false);
  const [filterByTypes, setFilterByTypes] = useState([]);

  const handleFilterByType = (tag) => {
    setFilterByTypes((prev) => {
      const tags = [...prev];
      const index = tags.indexOf(tag);
      if (index !== -1) {
        tags.splice(index, 1);
      } else {
        tags.push(tag);
      }

      return tags;
    });
  };

  useEffect(() => {
    onFilterTag(filterByTypes);
  }, [filterByTypes, onFilterTag]);

  return (
    <div className='border-b md:border-0'>
      {showFilters && (
        <div>
          <div className="absolute w-[320px] ml-4 bg-white rounded-sm shadow z-50">
            <div className="flex px-4 pt-4">
              <div className="uppercase font-bold text-gray-500">Filters</div>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                onClick={() => {
                  setShowFilters(false);
                }}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
            <div className="px-4 pt-2 pb-8 text-gray-500">
              <div className='font-medium text-base text-gray-900 mb-4'>Types</div>
              <div className="flex gap-4 flex-wrap mb-4">
                {modelTypes &&
                  modelTypes.map((tag) => {
                    const isTagSelected = filterByTypes.includes(tag);
                    return (
                      <button
                        type="button"
                        onClick={() => {
                          handleFilterByType(tag);
                        }}
                        className={`${isTagSelected ? 'bg-gray-300' : 'bg-gray-100'} text-gray-900 rounded p-1 text-sm flex`}
                        key={tag}
                      >
                        {tag}
                      </button>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex mx-4 justify-between gap-4 md:gap-0 my-6">
        <div>
          <button
            type="button"
            disabled={!modelTypes.length}
            onClick={() => {
              setShowFilters(true);
            }}
            className={`${
              !modelTypes.length ? 'opacity-20' : ''
            }  inline-flex items-center px-3 py-2 font-medium text-center text-gray-900 bg-white rounded-lg border border-black text-base`}
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.9999 11.6667V3.33337V11.6667Z" fill="#1F2A37" />
              <path
                d="M9.99992 5.00004V3.33337M9.99992 5.00004C9.55789 5.00004 9.13397 5.17564 8.82141 5.4882C8.50885 5.80076 8.33325 6.22468 8.33325 6.66671C8.33325 7.10873 8.50885 7.53266 8.82141 7.84522C9.13397 8.15778 9.55789 8.33337 9.99992 8.33337M9.99992 5.00004C10.4419 5.00004 10.8659 5.17564 11.1784 5.4882C11.491 5.80076 11.6666 6.22468 11.6666 6.66671C11.6666 7.10873 11.491 7.53266 11.1784 7.84522C10.8659 8.15778 10.4419 8.33337 9.99992 8.33337M9.99992 8.33337V16.6667M4.99992 15C5.44195 15 5.86587 14.8244 6.17843 14.5119C6.49099 14.1993 6.66659 13.7754 6.66659 13.3334C6.66659 12.8913 6.49099 12.4674 6.17843 12.1549C5.86587 11.8423 5.44195 11.6667 4.99992 11.6667M4.99992 15C4.55789 15 4.13397 14.8244 3.82141 14.5119C3.50885 14.1993 3.33325 13.7754 3.33325 13.3334C3.33325 12.8913 3.50885 12.4674 3.82141 12.1549C4.13397 11.8423 4.55789 11.6667 4.99992 11.6667M4.99992 15V16.6667M4.99992 11.6667V3.33337M14.9999 15C15.4419 15 15.8659 14.8244 16.1784 14.5119C16.491 14.1993 16.6666 13.7754 16.6666 13.3334C16.6666 12.8913 16.491 12.4674 16.1784 12.1549C15.8659 11.8423 15.4419 11.6667 14.9999 11.6667M14.9999 15C14.5579 15 14.134 14.8244 13.8214 14.5119C13.5088 14.1993 13.3333 13.7754 13.3333 13.3334C13.3333 12.8913 13.5088 12.4674 13.8214 12.1549C14.134 11.8423 14.5579 11.6667 14.9999 11.6667M14.9999 15V16.6667M14.9999 11.6667V3.33337"
                stroke="#111928"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className='px-2'>Filter</div>
          </button>
        </div>
        <div className="border-gray-300 bg-gray-50 border rounded-lg px-2 flex items-center md:w-[320px]">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.75 15.75L11.25 11.25M12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z"
              stroke="#6B7280"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <input
            type="text"
            onChange={onFilterName}
            value={filterName}
            className="bg-gray-50 border-0 text-gray-900 text-sm focus:ring-0 block w-full p-1"
            placeholder="Search"
          />
        </div>
      </div>
    </div>
  );
}
