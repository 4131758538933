import { Alert, Snackbar } from '@mui/material';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAccount, useBalance } from 'wagmi';

import { Container } from '../components/Container';
import Controls from '../components/form/Controls';
import { Form, useForm } from '../components/form/useForm';
import { Hero } from '../components/Hero';
import { Back } from '../components/Back';
import Budget, { validateBudget } from '../components/web3/Budget';
import { forkModel, getModelById } from '../service';

const initialFormValues = {
  description: '',
  tags: '',
  text: '',
  payout: '',
  budget: '',
};

export default function ModelForkPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [model, setModel] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [forkModelIsLoading, setForkModelIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [startCreateJob, setStartCreateJob] = useState(false);

  const { address } = useAccount();
  const { data: balance } = useBalance({ address });

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {
    const model = await getModelById(id);

    if (!model) {
      navigate(`/404`);
    } else {
      setModel(model);
      setLoading(false);

      initialFormValues.description = model.description;
    }
  };

  const validate = (fieldValues = values) => {
    const temp = { ...errors };

    if ('description' in fieldValues) {
      temp.description = fieldValues.description ? '' : 'This field is required.';
    }

    const budgetValidation = validateBudget(fieldValues, values, balance);

    setErrors({ ...temp, ...budgetValidation });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }

    return false;
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFormValues, true, validate);

  const handleForkJob = async (jobId) => {
    const model = await forkModel(id, jobId, values);

    if (model.success) {
      navigate(`/dashboard/model/${model.data.id}`);
    } else {
      setAlertMessage(model.message);
      setOpenAlert(true);
      setForkModelIsLoading(false);
    }
  };

  const handleForkJobError = async (error) => {
    setStartCreateJob(false);
    setForkModelIsLoading(false);
    setAlertMessage(error.message.split('.')[0]);
    setOpenAlert(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setForkModelIsLoading(true);
      if (values.payout && values.budget) {
        setStartCreateJob(true);
      } else {
        handleForkJob();
      }
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <Container>
      <Snackbar open={openAlert} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error" onClose={handleCloseAlert} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Hero
        leftItem={
          <Back
            onClick={() => {
              navigate(-1);
            }}
          />
        }
        copy="Fork Model"
      />
      <Form onSubmit={handleSubmit}>
        <div className="mt-6 p-6 bg-white border border-gray-200 rounded-lg w-full shadow">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Model information</h5>

          <div>
            <Controls.Input
              name="description"
              label="Title"
              value={values.description || model.description}
              onChange={handleInputChange}
              error={errors.description}
              disabled={loading}
            />
          </div>
          <div>
            <Controls.Input
              name="text"
              type="textarea"
              label="Description"
              value={values.text || model.text}
              onChange={handleInputChange}
              error={errors.text}
              disabled={loading}
            />
          </div>
          <div>
            <Controls.Input
              name="tags"
              label="Tags"
              value={values.tags || model.tags}
              onChange={handleInputChange}
              error={errors.tags}
              disabled={loading}
            />
          </div>
        </div>

        <div className="mt-6 p-6 bg-white border border-gray-200 rounded-lg w-full shadow">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Payment (Optional)</h5>

          <ConnectButton />

          <Budget
            startCreateJob={startCreateJob}
            values={values}
            errors={errors}
            handleInputChange={handleInputChange}
            handleCreateJobSuccess={handleForkJob}
            handleCreateJobError={handleForkJobError}
            disabled={loading}
          />
        </div>

        <div className="mt-6 flex gap-4">
          <button
            type="submit"
            disabled={loading || startCreateJob || forkModelIsLoading}
            className="inline-flex items-center px-3 py-2 font-medium text-center text-white bg-gray-900 rounded-lg hover:bg-gray-800 text-base"
          >
            {!startCreateJob && !forkModelIsLoading && 'Fork'}
            {startCreateJob && 'Confirm the transaction on your Wallet...'}
            {!startCreateJob && forkModelIsLoading && 'Forking model...'}
          </button>
          <button
            type="button"
            onClick={() => {
              navigate(-1);
            }}
            disabled={loading || startCreateJob || forkModelIsLoading}
            className="inline-flex items-center px-3 py-2 font-medium text-center text-gray-900 bg-white rounded-lg border border-black text-base"
          >
            Cancel
          </button>
        </div>
      </Form>
    </Container>
  );
}
