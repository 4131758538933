import { ethers } from 'ethers';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useContractRead } from 'wagmi';

import { Back } from '../components/Back';
import { Card } from '../components/Card';
import { Container } from '../components/Container';
import { Hero } from '../components/Hero';
import ModelMenu from '../components/nav-section/ModelMenu';
import { AppModelAccuracy, AppModelsUpdate } from '../sections/@dashboard/app';
import getModels, { getModelById } from '../service';
import JobMarketplaceV2 from '../service/JobMarketplaceV2.json';
import { fData, fNumber, fShortenNumber } from '../utils/formatNumber';

// sections
const MainDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [models, setModels] = useState([]);
  const [_, setModelsCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    const models = await getModels();

    setModels(models);
    setModelsCount(models.length);
    setLoading(false);
  };

  return (
    <Container>
      <Hero copy={'Getting Started'} />
      <div className="flex justify-center items-start md:items-stretch flex-col md:flex-row gap-6 pb-6">
        <Card
          title="Submit a Model"
          copy="Get started by submitting a new model for which you need training data."
          buttonLabel="Submit Model"
          buttonAction={() => {
            navigate('/dashboard/model/submit');
          }}
        />
        <Card
          title="Browse Models"
          copy="Not ready to submit a model? Browse and fork existing models."
          buttonLabel="Browse Models"
          buttonAction={() => {
            navigate(`/dashboard/model/`);
          }}
        />
      </div>
      <AppModelsUpdate title="Latest Models" loading={loading} list={models.filter((o, i) => i < 5)} />
    </Container>
  );
};

const ModelDashboard = (props) => {
  const { id } = props;
  const [loading, setLoading] = useState(true);
  const [model, setModel] = useState({});
  const navigate = useNavigate();

  const { data } = useContractRead({
    chainId: process.env.REACT_APP_DEFAULT_CHAIN_ID,
    address: process.env.REACT_APP_JOBMARKETPLACEV2_ADDRESS,
    abi: JobMarketplaceV2.abi,
    functionName: 'jobs',
    args: [model.job_id],
    enabled: Boolean(model.job_id),
  });

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {
    const model = await getModelById(id);

    if (!model) {
      navigate(`/404`);
    } else {
      if (model.accuracy_history) {
        model.accuracy_history.sort((a, b) => a.round - b.round);
      }

      setModel(model);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Hero
        leftItem={
          <Back
            onClick={() => {
              navigate(-1);
            }}
          />
        }
        copy={model.description}
        rightItem={<ModelMenu id={id} description={model.description} jobId={model.job_id?.toString()} />}
      />
      {((model.tags && model.tags.length > 0) || model.text) && (
        <div className="p-6 bg-white border border-gray-200 rounded-lg shadow">
          <div className="flex flex-col gap-4 text-gray-500 text-xs">
            {model.tags && model.tags.length > 0 && (
              <div className="flex gap-1">
                {model.tags.map((tag) => (
                  <>
                    {tag && (
                      <div className="bg-gray-100 rounded p-1 flex" key={tag}>
                        {tag}
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}
            {model.text}
          </div>
        </div>
      )}

      {data && data[0] && (
        <div className="flex flex-col md:flex-row gap-6 justify-between mt-6">
          {[
            { title: 'Budget', description: ethers.formatEther(data[2]) },
            { title: 'Payout', description: ethers.formatEther(data[1]) },
            { title: 'Blockchain Job ID', description: model.job_id },
            { title: 'Owner Wallet', description: data[0] },
          ].map((item, index) => (
            <MiniCard key={index} title={item.title} description={item.description} />
          ))}
        </div>
      )}

      <div className="flex flex-col md:flex-row gap-6 justify-between mt-6">
        {[
          { title: 'Accuracy', description: model.accuracy ? fData(model.accuracy) : '-' },
          { title: 'Rounds', description: fShortenNumber(model.rounds) },
          { title: 'Completed Rounds', description: Number(model.completed_training_rounds) },
          { title: 'Registered Trainers', description: fNumber(data ? data[4] : undefined) },
        ].map((item, index) => (
          <MiniCard key={index} title={item.title} description={item.description} />
        ))}
      </div>

      {model.accuracy && (
        <AppModelAccuracy
          title="Model accuracy"
          subheader="over last training rounds"
          chartLabels={model.accuracy_history.map((accuracy) => accuracy.round)}
          chartData={[
            {
              name: 'App',
              type: 'line',
              fill: 'solid',
              data: model.accuracy_history.map((accuracy) => fData(accuracy.accuracy)),
            },
          ]}
        />
      )}
    </Container>
  );
};

const MiniCard = ({ title, description }) => (
  <div className="p-6 basis-1 break-all grow bg-white border border-gray-200 rounded-lg shadow">
    <h3 className="font-bold text-xl">{title}</h3>
    <p className="text-gray-500 text-sm">{description}</p>
  </div>
);

MiniCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

ModelDashboard.propTypes = {
  id: PropTypes.string.isRequired,
};

export default function DashboardAppPage() {
  const { id } = useParams();

  return <>{id ? <ModelDashboard id={id} /> : <MainDashboard />}</>;
}
