import { Alert, Button, Skeleton, Snackbar, Typography } from '@mui/material';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAccount,
  useBalance,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';

import { Back } from '../components/Back';
import { Container } from '../components/Container';
import Controls from '../components/form/Controls';
import { Form, useForm } from '../components/form/useForm';
import { Hero } from '../components/Hero';
import { getModelById } from '../service';
import JobMarketplaceV2 from '../service/JobMarketplaceV2.json';

// @mui
const initialFormValues = {
  budget: '',
};

export default function ModelIncreasBudgetPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [model, setModel] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { address } = useAccount();
  const { data: balance } = useBalance({ address });

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {
    const model = await getModelById(id);

    if (!model) {
      navigate(`/404`);
    } else {
      setModel(model);
      setLoading(false);
    }
  };

  const validate = (fieldValues = values) => {
    const temp = { ...errors };

    if ('budget' in fieldValues) {
      if (!fieldValues.budget || fieldValues.budget <= 0) {
        temp.budget = 'This field is required.';
      } else if (fieldValues.budget > Number(ethers.formatEther(balance.value))) {
        temp.budget = `The budget cannot be greater than your balance.`;
      } else {
        temp.budget = '';
      }
    }

    setErrors({ ...temp });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }

    return false;
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFormValues, true, validate);

  const { data } = useContractRead({
    chainId: process.env.REACT_APP_DEFAULT_CHAIN_ID,
    address: process.env.REACT_APP_JOBMARKETPLACEV2_ADDRESS,
    abi: JobMarketplaceV2.abi,
    functionName: 'jobs',
    args: [model.job_id],
    enabled: Boolean(model.job_id),
  });

  const { config } = usePrepareContractWrite({
    address: process.env.REACT_APP_JOBMARKETPLACEV2_ADDRESS,
    abi: JobMarketplaceV2.abi,
    functionName: 'increaseJobBudget',
    args: [model.job_id],
    value: ethers.parseEther(values.budget?.toString() || '0'),
    enabled: Boolean(model.job_id) && values.budget > 0,
  });

  const {
    write: increaseJobBudgetWrite,
    data: increaseJobBudgetData,
    isLoading: increaseJobBudgetIsLoading,
    error: increaseJobBudgetError,
  } = useContractWrite(config);

  const { data: increaseJobBudgetReceiptData, isLoading: increaseJobBudgetReceiptIsLoading } = useWaitForTransaction({
    hash: increaseJobBudgetData?.hash,
  });

  useEffect(() => {
    if (increaseJobBudgetReceiptData) {
      navigate(`/dashboard/model/${id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [increaseJobBudgetReceiptData]);

  useEffect(() => {
    if (increaseJobBudgetError) {
      setAlertMessage(increaseJobBudgetError.message.split('.')[0]);
      setOpenAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [increaseJobBudgetError]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      increaseJobBudgetWrite?.();
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <>
      <Container>
        <Snackbar open={openAlert} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity="error" onClose={handleCloseAlert} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>

        <Hero
          leftItem={
            <Back
              onClick={() => {
                navigate(-1);
              }}
            />
          }
          copy="Increase Model Budget"
        />

        <Form onSubmit={handleSubmit}>
          <div className="mt-6 p-6 bg-white border border-gray-200 rounded-lg w-full shadow">
            <div className='flex justify-between pb-8'>
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                {(loading || (model.job_id && address)) && (
                  <>
                    Current budget:{' '}
                    {loading ? (
                      <Skeleton variant="text" sx={{ display: 'inline-block', width: '50px' }} />
                    ) : (
                      <>
                        {data && data[2] ? ethers.formatEther(data[2]) : '0'} {process.env.REACT_APP_BUDGET_TOKEN_NAME}
                      </>
                    )}
                  </>
                )}
                {!loading && !model.job_id && <>This model don't have blockchain budget to be increased.</>}
                {model.job_id && !address && <>Please connect to your wallet to increase the budget.</>}
              </h5>
              <ConnectButton />
            </div>

            {(loading || (model.job_id && address)) && (
              <>
                <Controls.Input
                  type="number"
                  name="budget"
                  label="Amount to increase"
                  value={values.budget}
                  onChange={handleInputChange}
                  error={errors.budget}
                  fullWidth
                  focused
                  disabled={loading}
                  placeholder="Enter the amount by which you wish to increase the budget"
                />
              </>
            )}
          </div>
          <div className="mt-6 flex gap-4">
            <button
              type="submit"
              disabled={loading || increaseJobBudgetIsLoading || increaseJobBudgetReceiptIsLoading}
              className="inline-flex items-center px-3 py-2 font-medium text-center text-white bg-gray-900 rounded-lg hover:bg-gray-800 text-base"
            >
              {!increaseJobBudgetIsLoading && !increaseJobBudgetReceiptIsLoading && 'Increase budget'}
              {increaseJobBudgetIsLoading && 'Confirm the transaction on your Wallet...'}
              {!increaseJobBudgetIsLoading && increaseJobBudgetReceiptIsLoading && 'Increasing budget...'}
            </button>
            <button
              type="button"
              onClick={() => {
                navigate(-1);
              }}
              disabled={loading || increaseJobBudgetIsLoading || increaseJobBudgetReceiptIsLoading}
              className="inline-flex items-center px-3 py-2 font-medium text-center text-gray-900 bg-white rounded-lg border border-black text-base"
            >
              Cancel
            </button>
          </div>
        </Form>
      </Container>
    </>
  );
}
