import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { ethers } from 'ethers';
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';

import JobMarketplaceV2 from '../../service/JobMarketplaceV2.json';
import { getEventValue } from '../../utils/web3';

import Controls from '../form/Controls';

export function validateBudget(fieldValues, values, balance) {
  const temp = {};

  if ('payout' in fieldValues) {
    temp.payout = "";
    temp.budget = "";

    if (values.budget) {
      if (!fieldValues.payout) {
        temp.payout = "This field is required when budget is informed.";
      } else if (fieldValues.payout > values.budget) {
        temp.payout = "The payout cannot be greater than budget.";
      }
    } else {
      temp.budget = fieldValues.payout ? "This field is required when payout is informed." : "";
    }
  }

  if ('budget' in fieldValues) {
    temp.budget = "";
    temp.payout = "";

    if (fieldValues.budget > Number(ethers.formatEther(balance.value))) {
      temp.budget = `The budget cannot be greater than your balance.`;
    }

    if (values.payout) {
      if (!fieldValues.budget) {
        temp.budget = "This field is required when payout is informed.";
      } else if (fieldValues.budget < values.payout) {
        temp.budget = "The budget cannot be lower than payout.";
      }
    } else {
      temp.payout = fieldValues.budget ? "This field is required when budget is informed." : "";
    }
  }

  return temp;
}

export default function Budget(props) {
  const {
    startCreateJob,
    values,
    errors,
    handleInputChange,
    handleCreateJobSuccess,
    handleCreateJobError,
    disabled,
  } = props;

  const { address } = useAccount();

  const { config } = usePrepareContractWrite({
    address: process.env.REACT_APP_JOBMARKETPLACEV2_ADDRESS,
    abi: JobMarketplaceV2.abi,
    functionName: "createJob",
    args: [ethers.parseEther(values.payout?.toString() || "0")],
    value: ethers.parseEther(values.budget?.toString() || "0"),
    enabled: values.payout > 0 && values.budget > 0,
  });

  const {
    write: createJobWrite,
    data: createJobData,
    error: createJobError,
  } = useContractWrite(config);

  const {
    data: createJobReceiptData,
  } = useWaitForTransaction({
    hash: createJobData?.hash,
  });

  useEffect(() => {
    if (startCreateJob) {
      createJobWrite();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startCreateJob]);

  useEffect(() => {
    if (createJobReceiptData) {
      const jobId = getEventValue(JobMarketplaceV2.abi, createJobReceiptData, "JobCreated", "jobId");
      handleCreateJobSuccess(jobId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createJobReceiptData]);

  useEffect(() => {
    if (createJobError) {
      handleCreateJobError(createJobError);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createJobError]);

  if (!address) {
    return (
      <div>
        <Typography variant="body2" gutterBottom color={'grey.700'}>
          Please connect to your wallet to setup the budget.<br/>
        </Typography>
      </div>
    );
  }

  return (
    <>
      <div style={{ marginBottom: "15px" }}>
        <Typography variant="body2" gutterBottom color={'grey.700'}>
          Setting a payout and budget will allow you to pay the trainers for their work.<br/>
          A new job will be created on the blockchain with the payout and budget you set.<br/>
        </Typography>
      </div>

      <Stack spacing={2} direction="row" sx={{mb: 2}}>
        <Controls.Input
          type='number'
          name="payout"
          label="Payout"
          value={values.payout}
          onChange={handleInputChange}
          error={errors.payout}
          fullWidth
          disabled={disabled}
          placeholder='Enter the amount which testers will receive for successfully training the model '
        />

        <Controls.Input
          type='number'
          name="budget"
          label="Total budget"
          value={values.budget}
          onChange={handleInputChange}
          error={errors.budget}
          fullWidth
          disabled={disabled}
          placeholder='Enter the amount by which you wish to fund the model training'
        />
      </Stack>
    </>
  )
}

Budget.propTypes = {
  startCreateJob: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleCreateJobSuccess: PropTypes.func.isRequired,
  handleCreateJobError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
