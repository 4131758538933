import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// RainbowKit + wagmi
import '@rainbow-me/rainbowkit/styles.css';
import './tailwind-output.css';

import { getDefaultWallets, RainbowKitProvider, lightTheme } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { polygonMumbai } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

// routes
import Router from './routes';

// theme
import ThemeProvider from './theme';

// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { AuthProvider } from './hooks/useAuth';

const { chains, publicClient } = configureChains(
  [polygonMumbai],
  [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_ID || 'D07RGQ95Cv9eY7GscTkNczNRBkSH1dWn' }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: '50b',
  projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || '30c60c879aed1b4bc2d9647ee6fac782',
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

export default function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        chains={chains}
        theme={lightTheme({
          accentColor: '#000',
          accentColorForeground: 'white',
          borderRadius: 'small',
          fontStack: 'system',
          overlayBlur: 'small',
        })}
      >
        <HelmetProvider>
          <BrowserRouter>
            <ThemeProvider>
              <ScrollToTop />
              <StyledChart />
              <AuthProvider>
                <Router />
              </AuthProvider>
            </ThemeProvider>
          </BrowserRouter>
        </HelmetProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
