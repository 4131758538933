import axios from 'axios';
import { useEffect } from 'react';

import Iconify from '../components/iconify';
import { useAuth } from '../hooks/useAuth';

export default function LoginPage() {
  const auth = useAuth();

  useEffect(() => {
    const jwt = localStorage.getItem('JWT');
    if (jwt == null || jwt === 'null') {
      const query = new URLSearchParams(window.location.search);
      const token = query.get('jwt');

      if (token) {
        auth.login(token);
        auth.configureAuthorizationHeader();
      }
    }
  }, []);

  const handleLogin = (e) => {
    const BACKEND_URL = process.env.REACT_APP_API_URL;
    e.preventDefault();
    axios
      .get(`${BACKEND_URL}/auth/google`, {
        headers: {
          'Access-Control-Allow-Origin': '* ',
          'Access-Control-Allow-Headers': 'Content-Type',
        },
      })
      .then((res) => {
        window.location.assign(res.data.auth_url);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="h-screen w-screen">
      <div className="w-full h-full flex justify-center items-center">
        <div className="p-12 bg-white border border-gray-200 rounded-lg w-96 shadow">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Welcome to BCI OS</h5>
          <p className="py-6 font-normal text-base text-gray-500">Log in to our platform with your Google account.</p>
          <button
            type="button"
            onClick={handleLogin}
            className="flex justify-center w-full items-center px-3 py-2 font-medium text-center text-white bg-gray-900 rounded-lg hover:bg-gray-800 text-base"
          >
            <Iconify icon="eva:google-fill" color="#FFF" width={22} height={22} className="mr-4" /> Continue with Google
          </button>
        </div>
      </div>
    </div>
  );
}
