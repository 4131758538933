import { Divider, Menu, MenuItem, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { deleteModel } from '../../service';

ModelMenu.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  jobId: PropTypes.string,
};

export default function ModelMenu({ id, description, jobId }) {
  const navigate = useNavigate();

  const [menuEl, setMenuEl] = useState(null);
  const menuOpen = Boolean(menuEl);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setMenuEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuEl(null);
  };

  const handleEditModel = () => {
    navigate(`/dashboard/model/edit/${id}`);
  };

  const handleForkModel = () => {
    navigate(`/dashboard/model/fork/${id}`);
  };

  const handleIncreaseBudgetModel = () => {
    if (jobId) {
      navigate(`/dashboard/model/budget/increase/${id}`);
    }
  };

  const handleAddBudgetModel = () => {
    if (!jobId) {
      navigate(`/dashboard/model/budget/add/${id}`);
    }
  };

  const handleOpenDeleteDialog = () => {
    handleCloseMenu();
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteModel = async () => {
    const model = await deleteModel(id);

    if (model.success) {
      window.location.reload();
    }
  };

  return (
    <>
      {openDeleteDialog && (
        <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center bg-opacity-20 bg-black w-full md:inset-0 h-[100%] max-h-full">
          <div className="relative p-4 w-full max-h-full h-full">
            <div className="flex justify-center items-center h-full">
              <div className="relative w-96 bg-white rounded-lg shadow">
                <div className="flex pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                    onClick={() => {
                      handleCloseDeleteDialog();
                    }}
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="flex justify-center items-center">
                  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18 11V18M18 25H18.0175M33.75 18C33.75 20.0683 33.3426 22.1164 32.5511 24.0273C31.7596 25.9381 30.5995 27.6744 29.1369 29.1369C27.6744 30.5995 25.9381 31.7596 24.0273 32.5511C22.1164 33.3426 20.0683 33.75 18 33.75C15.9317 33.75 13.8836 33.3426 11.9727 32.5511C10.0619 31.7596 8.32559 30.5995 6.86307 29.1369C5.40055 27.6744 4.24041 25.9381 3.4489 24.0273C2.65739 22.1164 2.25 20.0683 2.25 18C2.25 13.8228 3.90937 9.81677 6.86307 6.86307C9.81677 3.90937 13.8228 2.25 18 2.25C22.1772 2.25 26.1832 3.90937 29.1369 6.86307C32.0906 9.81677 33.75 13.8228 33.75 18Z"
                      stroke="#9CA3AF"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="p-4 text-sm text-gray-500 text-center">
                  Are you sure you want to delete the model "{description}"?
                  <br />
                  <br />
                  This action cannot be undone.
                </div>
                <div className="m-4 flex gap-4 items-center justify-center">
                  <button
                    type="button"
                    onClick={() => {
                      handleDeleteModel();
                    }}
                    className="inline-flex bg-red-500 items-center px-3 py-2 font-medium text-center text-white rounded-lg  text-sm"
                  >
                    Yes, I’m sure
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleCloseDeleteDialog();
                    }}
                    className="inline-flex items-center px-3 py-2 font-medium text-center text-gray-900 bg-white rounded-lg border border-black text-sm"
                  >
                    No, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <button type="button" onClick={(e) => handleOpenMenu(e)} className="md:p-4 pr-4">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6 10C6 10.5304 5.78929 11.0391 5.41421 11.4142C5.03914 11.7893 4.53043 12 4 12C3.46957 12 2.96086 11.7893 2.58579 11.4142C2.21071 11.0391 2 10.5304 2 10C2 9.46957 2.21071 8.96086 2.58579 8.58579C2.96086 8.21071 3.46957 8 4 8C4.53043 8 5.03914 8.21071 5.41421 8.58579C5.78929 8.96086 6 9.46957 6 10ZM12 10C12 10.5304 11.7893 11.0391 11.4142 11.4142C11.0391 11.7893 10.5304 12 10 12C9.46957 12 8.96086 11.7893 8.58579 11.4142C8.21071 11.0391 8 10.5304 8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8C10.5304 8 11.0391 8.21071 11.4142 8.58579C11.7893 8.96086 12 9.46957 12 10ZM16 12C16.5304 12 17.0391 11.7893 17.4142 11.4142C17.7893 11.0391 18 10.5304 18 10C18 9.46957 17.7893 8.96086 17.4142 8.58579C17.0391 8.21071 16.5304 8 16 8C15.4696 8 14.9609 8.21071 14.5858 8.58579C14.2107 8.96086 14 9.46957 14 10C14 10.5304 14.2107 11.0391 14.5858 11.4142C14.9609 11.7893 15.4696 12 16 12Z"
            fill="#111928"
          />
        </svg>
      </button>
      <Menu
        id="basic-menu"
        anchorEl={menuEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleEditModel}>Edit model</MenuItem>
        <MenuItem onClick={handleForkModel}>Fork model</MenuItem>

        {jobId && <MenuItem onClick={handleIncreaseBudgetModel}>Increase model budget</MenuItem>}
        {!jobId && <MenuItem onClick={handleAddBudgetModel}>Add model budget</MenuItem>}

        <Divider />
        <MenuItem onClick={handleOpenDeleteDialog}>
          <Typography variant="inherit" color="red">
            Delete model
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
