import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';

import { useChart } from '../../../components/chart';

AppModelAccuracy.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default function AppModelAccuracy({ title, subheader, chartLabels, chartData }) {
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'int' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y} accuracy`;
          }
          return y;
        },
      },
    },
  });

  return (
    <div className="p-6 mt-6 w-full bg-white border border-gray-200 rounded-lg shadow">
      <h3 className="font-bold text-xl">{title}</h3>
      <p className="text-gray-500 text-sm">{subheader}</p>
      <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
    </div>
  );
}
