import { Alert, Snackbar } from '@mui/material';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAccount, useBalance } from 'wagmi';

import { Back } from '../components/Back';
import { Container } from '../components/Container';
import { Form, useForm } from '../components/form/useForm';
import { Hero } from '../components/Hero';
import Budget, { validateBudget } from '../components/web3/Budget';
import { getModelById, updateModel } from '../service';

// @mui
const initialFormValues = {
  payout: '',
  budget: '',
};

export default function ModelAddBudgetPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [model, setModel] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [updateModelIsLoading, setUpdateModelIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [startCreateJob, setStartCreateJob] = useState(false);

  const { address } = useAccount();
  const { data: balance } = useBalance({ address });

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {
    const model = await getModelById(id);

    if (!model) {
      navigate(`/404`);
    } else {
      setModel(model);
      setLoading(false);
    }
  };

  const validate = (fieldValues = values) => {
    const temp = { ...errors };

    const budgetValidation = validateBudget(fieldValues, values, balance);

    setErrors({ ...temp, ...budgetValidation });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }

    return false;
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFormValues, true, validate);

  const handleUpdateJob = async (jobId) => {
    const model = await updateModel(id, jobId);

    if (model.success) {
      navigate(`/dashboard/model/${model.data.id}`);
    } else {
      setAlertMessage(model.message);
      setOpenAlert(true);
      setUpdateModelIsLoading(false);
    }
  };

  const handleUpdateJobError = async (error) => {
    setStartCreateJob(false);
    setUpdateModelIsLoading(false);
    setAlertMessage(error.message.split('.')[0]);
    setOpenAlert(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setUpdateModelIsLoading(true);
      if (values.payout && values.budget) {
        setStartCreateJob(true);
      } else {
        handleUpdateJob();
      }
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <Container>
      <Snackbar open={openAlert} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error" onClose={handleCloseAlert} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>

      <Hero
        leftItem={
          <Back
            onClick={() => {
              navigate(-1);
            }}
          />
        }
        copy="Add Model Budget"
      />

      <Form onSubmit={handleSubmit}>
        <div className="mt-6 p-6 bg-white border border-gray-200 rounded-lg w-full shadow">
          <div className="flex justify-between pb-8">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Payment and Budget</h5>

            <ConnectButton />
          </div>

          <Budget
            startCreateJob={startCreateJob}
            values={values}
            errors={errors}
            handleInputChange={handleInputChange}
            handleCreateJobSuccess={handleUpdateJob}
            handleCreateJobError={handleUpdateJobError}
            disabled={loading}
          />
        </div>

        <div className="mt-6 flex gap-4">
          <button
            type="submit"
            disabled={loading || startCreateJob || updateModelIsLoading}
            className="inline-flex items-center px-3 py-2 font-medium text-center text-white bg-gray-900 rounded-lg hover:bg-gray-800 text-base"
          >
            {!startCreateJob && !updateModelIsLoading && 'Add budget'}
            {startCreateJob && 'Confirm the transaction on your Wallet...'}
            {!startCreateJob && updateModelIsLoading && 'Adding budget...'}
          </button>
          <button
            type="button"
            onClick={() => {
              navigate(-1);
            }}
            disabled={loading || startCreateJob || updateModelIsLoading}
            className="inline-flex items-center px-3 py-2 font-medium text-center text-gray-900 bg-white rounded-lg border border-black text-base"
          >
            Cancel
          </button>
        </div>
      </Form>
    </Container>
  );
}
