import { Navigate, useRoutes } from 'react-router-dom';
import PropTypes from 'prop-types';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

// pages
import LoginPage from './pages/LoginPage';
import DashboardAppPage from './pages/DashboardAppPage';
import ModelListPage from './pages/ModelListPage';
import ModelSubmitPage from './pages/ModelSubmitPage';
import ModelEditPage from './pages/ModelEditPage';
import ModelForkPage from './pages/ModelForkPage';
import ModelIncreaseBudgetPage from './pages/ModelIncreaseBudgetPage';
import ModelAddBudgetPage from './pages/ModelAddBudgetPage';
import Page404 from './pages/Page404';

import { useAuth } from './hooks/useAuth';

export const ProtectedRoute = ({ children }) => {
  const auth = useAuth();

  if (!auth?.jwt) {
    localStorage.setItem('returnUrl', window.location.pathname);
    return <Navigate to="/login" />;
  }

  auth.configureAuthorizationHeader();
  return children;
};

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          element: <Navigate to="/dashboard/app" />,
          index: true,
        },
        {
          path: 'app',
          element: (
            <ProtectedRoute>
              <DashboardAppPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'model',
          element: (
            <ProtectedRoute>
              <ModelListPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'model/:id',
          element: (
            <ProtectedRoute>
              <DashboardAppPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'model/submit',
          element: (
            <ProtectedRoute>
              <ModelSubmitPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'model/edit/:id',
          element: (
            <ProtectedRoute>
              <ModelEditPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'model/fork/:id',
          element: (
            <ProtectedRoute>
              <ModelForkPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'model/budget/increase/:id',
          element: (
            <ProtectedRoute>
              <ModelIncreaseBudgetPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'model/budget/add/:id',
          element: (
            <ProtectedRoute>
              <ModelAddBudgetPage />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        {
          element: <Navigate to="/dashboard/app" />,
          index: true,
        },
        {
          path: '404',
          element: (
            <ProtectedRoute>
              <Page404 />
            </ProtectedRoute>
          ),
        },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
