import { Skeleton } from '@mui/material';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container } from '../components/Container';
import { Hero } from '../components/Hero';
import ModelMenu from '../components/nav-section/ModelMenu';
import { ModelListHead, ModelListToolbar } from '../sections/@dashboard/model';
import getModels from '../service';
import { fData, fNumber } from '../utils/formatNumber';
import { fDate } from '../utils/formatTime';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, name, tags) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  let filtered = stabilizedThis.map((el) => el[0]);
  if (name) {
    filtered = filter(array, (_model) => _model.description.toLowerCase().indexOf(name.toLowerCase()) !== -1);
  }
  if (tags.length) {
    return filtered.filter((model) => {
      if (!model.tags.length) {
        return false;
      }

      return model.tags.some((tag) => tags.includes(tag));
    });
  }
  return filtered;
}

export default function ModelListPage() {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [filterTag, setFilterTag] = useState([]);
  const [models, setModels] = useState([]);
  const [modelTypes, setModelTyes] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [showingStart, setShowingStart] = useState(0);
  const [showingEnd, setShowingEnd] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;
  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    const models_response = await getModels();

    setModels(models_response);
    let tags = [];
    if (models_response && models_response.length) {
      models_response.forEach((model) => {
        if (model.tags) {
          tags = [...tags, ...model.tags];
        }
      });
      if (tags.length) {
        setModelTyes([...new Set([...tags])]);
      }
    }
    setLoading(false);
  };

  const navigate = useNavigate();

  const handleFilterByName = (event) => {
    if (event.target.value !== filterName) {
      setPage(0);
      setFilterName(event.target.value);
    }
  };

  const handleFilterByTag = (tags) => {
    if (tags.length || (filterTag.length && tags.sort().join('') !== filterTag.sort().join())) {
      setPage(0);
      setFilterTag(tags);
    }
  };

  useEffect(() => {
    const filtered = applySortFilter(models, getComparator(order, orderBy), filterName, filterTag);
    setFilteredModels(filtered);
  }, [order, orderBy, filterName, filterTag, models]);

  useEffect(() => {
    setShowingStart(itemsPerPage * page + 1);
    const lastItem = itemsPerPage * (page + 1);
    setShowingEnd(lastItem <= totalItems ? lastItem : totalItems);
  }, [page, totalItems]);

  useEffect(() => {
    setTotalItems(filteredModels.length);
    setTotalPages(Math.ceil(filteredModels.length / itemsPerPage));
  }, [filteredModels]);

  const handleOpenModel = (id) => {
    navigate(`/dashboard/model/${id}`);
  };

  return (
    <Container>
      <Hero
        copy="Models"
        rightItem={
          <button
            type="button"
            onClick={() => navigate('/dashboard/model/submit')}
            className="inline-flex items-center px-3 py-2 font-medium text-center text-white bg-gray-900 rounded-lg hover:bg-gray-800 text-base"
          >
            Submit Model
          </button>
        }
      />

      <div className="bg-white border border-gray-200 rounded-lg w-full shadow">
        <ModelListToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
          onFilterTag={handleFilterByTag}
          modelTypes={modelTypes}
        />
        <table className="table-auto will-change-auto w-full">
          <ModelListHead
            order={order}
            orderBy={orderBy}
            headLabel={[
              { id: 'description', label: 'Model Name', align: 'left' },
              { id: 'tags', label: 'Model Type', align: 'left' },
              { id: 'job_id', label: 'Has budget', align: 'center' },
              { id: 'completed_training_rounds', label: 'Rounds Completed', align: 'center' },
              { id: 'accuracy', label: 'Accuracy', align: 'center' },
              { id: 'created_at', label: 'Created', align: 'left' },
              { id: '' },
            ]}
            onRequestSort={(e, p, d) => {
              setOrderBy(p);
              setOrder(d);
            }}
          />
          {loading && (
            <tbody>
              {Array.from(new Array(5)).map((_, index) => (
                <tr key={index}>
                  <td align="center" colSpan={7}>
                    <Skeleton />
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {!loading && (
            <tbody>
              {filteredModels
                .filter((_, index) => index >= showingStart - 1 && index <= showingEnd - 1)
                .map((row, i) => {
                  const { id, description, accuracy, created_at, completed_training_rounds, job_id, tags } = row;
                  return (
                    <tr
                      key={i}
                      tabIndex={-1}
                      className="cursor-pointer text-sm border-b flex flex-col md:table-row pb-4 md:pb-0"
                    >
                      <td
                        align="left"
                        onClick={() => handleOpenModel(id)}
                        className="pt-4 pl-4 font-medium md:font-normal md:pt-0 flex md:table-cell justify-between items-start md:items-center"
                      >
                        <div>{description}</div>
                        <div className="md:hidden">
                          <ModelMenu id={id} description={description} jobId={job_id?.toString()} />
                        </div>
                      </td>
                      <td align="left" onClick={() => handleOpenModel(id)} className="pl-4 p-2">
                        {tags && tags.length > 0 && (
                          <div className="flex gap-1">
                            {tags.map((tag) => (
                              <>
                                {tag && (
                                  <div className="bg-gray-100 rounded p-1 flex" key={tag}>
                                    {tag}
                                  </div>
                                )}
                              </>
                            ))}
                          </div>
                        )}
                      </td>
                      <td className="px-4 md:p-0 md:text-center" onClick={() => handleOpenModel(id)}>
                        <span className="md:hidden text-gray-500">Has budget:</span> {job_id ? 'Yes' : 'No'}
                      </td>
                      <td className="px-4 md:p-0 md:text-center" onClick={() => handleOpenModel(id)}>
                        <span className="md:hidden text-gray-500">Rounds Completed:</span>{' '}
                        {fNumber(completed_training_rounds)}
                      </td>
                      <td className="px-4 md:p-0 md:text-center" onClick={() => handleOpenModel(id)}>
                        <span className="md:hidden text-gray-500">Rounds Accuracy:</span>{' '}
                        {accuracy ? fData(accuracy) : '-'}
                      </td>
                      <td className="px-4 md:p-0 md:text-left" onClick={() => handleOpenModel(id)}>
                        <span className="md:hidden text-gray-500">Created:</span> {fDate(created_at)}
                      </td>
                      <td align="right">
                        <span className="hidden md:block">
                          <ModelMenu id={id} description={description} jobId={job_id?.toString()} />
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
          {!filteredModels.length && (!!filterName || !!filterTag.length) && (
            <tbody>
              <tr>
                <td align="center" colSpan={6} className="text-sm p-6">
                  No results found for &nbsp;
                  {filterName && <strong>&quot;{filterName}&quot;</strong>}
                  <div>
                    {filterTag && filterTag.length > 0 && (
                      <div className="flex gap-2 flex-wrap mb-4 justify-center items-center">
                        <div className="font-bold">Types</div>
                        {filterTag.map((tag) => (
                          <div className={`bg-gray-100 rounded p-1 text-xs flex`} key={tag}>
                            {tag}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <br /> Try checking for typos or using complete words.
                </td>
              </tr>
            </tbody>
          )}
        </table>
        {filteredModels.length > 0 && (
          <div className="flex justify-between mx-4 mt-6 items-center text-gray-500">
            <div className="text-sm">
              Showing <span className="text-gray-900">{showingStart}</span>-
              <span className="text-gray-900">{showingEnd}</span> of <span className="text-gray-900">{totalItems}</span>
            </div>
            <div>
              <div className="flex m-4">
                <button
                  type="button"
                  className="inline-flex items-center disabled:opacity-30 w-9 p-1 font-medium text-center bg-white rounded-l-lg border border-gray-300"
                  disabled={showingStart === 1}
                  onClick={() => {
                    setPage((prev) => prev - 1);
                  }}
                >
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.707 5.79303C12.8945 5.98056 12.9998 6.23487 12.9998 6.50003C12.9998 6.76519 12.8945 7.0195 12.707 7.20703L9.41403 10.5L12.707 13.793C12.8892 13.9816 12.99 14.2342 12.9877 14.4964C12.9854 14.7586 12.8803 15.0094 12.6948 15.1948C12.5094 15.3803 12.2586 15.4854 11.9964 15.4877C11.7342 15.49 11.4816 15.3892 11.293 15.207L7.29303 11.207C7.10556 11.0195 7.00024 10.7652 7.00024 10.5C7.00024 10.2349 7.10556 9.98056 7.29303 9.79303L11.293 5.79303C11.4806 5.60556 11.7349 5.50024 12 5.50024C12.2652 5.50024 12.5195 5.60556 12.707 5.79303Z"
                      fill="#6B7280"
                    />
                  </svg>
                </button>
                {totalPages > 0 && (
                  <div className="flex">
                    {Array(totalPages)
                      .fill()
                      .map((_, index) => index + 1)
                      .map((p, index) => (
                        <button
                          key={index}
                          type="button"
                          className="flex items-center disabled:bg-gray-100 font-medium text-center text-sm w-8 justify-center bg-white border border-gray-300"
                          disabled={page === index}
                          onClick={() => {
                            setPage(index);
                          }}
                        >
                          {p}
                        </button>
                      ))}
                  </div>
                )}
                <button
                  className="inline-flex items-center disabled:opacity-30 w-9 p-1 font-medium text-center bg-white rounded-r-lg border border-gray-300"
                  disabled={showingEnd >= totalItems}
                  type="button"
                  onClick={() => {
                    setPage((prev) => prev + 1);
                  }}
                >
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.29303 15.207C7.10556 15.0195 7.00024 14.7651 7.00024 14.5C7.00024 14.2348 7.10556 13.9805 7.29303 13.793L10.586 10.5L7.29303 7.20698C7.11087 7.01838 7.01008 6.76578 7.01236 6.50358C7.01463 6.24138 7.1198 5.99057 7.30521 5.80516C7.49062 5.61975 7.74143 5.51458 8.00363 5.51231C8.26583 5.51003 8.51843 5.61082 8.70703 5.79298L12.707 9.79298C12.8945 9.98051 12.9998 10.2348 12.9998 10.5C12.9998 10.7651 12.8945 11.0195 12.707 11.207L8.70703 15.207C8.5195 15.3945 8.26519 15.4998 8.00003 15.4998C7.73487 15.4998 7.48056 15.3945 7.29303 15.207Z"
                      fill="#6B7280"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}
