import PropTypes from 'prop-types';

import { cn } from '../../utils/cn';

export default function Input(props) {
  const { type, name, label, value, disabled, error = null, onChange, placeholder } = props;
  return (
    <div className="mb-5 w-full">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        {label}
        {type === 'textarea' && (
          <textarea
            name={name}
            value={value}
            onChange={onChange}
            className={cn(
              'bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5',
              error && 'border-red-500'
            )}
            placeholder={placeholder}
            disabled={disabled}
            rows={5}
          >
            {value}
          </textarea>
        )}
        {type !== 'textarea' && (
          <input
            type={type || 'text'}
            name={name}
            value={value}
            onChange={onChange}
            className={cn(
              'bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5',
              error && 'border-red-500'
            )}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}

        {error && <div className="text-xs pt-2 text-red-500">{error}</div>}
      </label>
    </div>
  );
}

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};
